<template>
  <div class="overflow-visible">
    <pp-simple-data class="text-xl font-medium">
      <template #left>
        <div>Total APR</div>
      </template>

      <template #right>
        <pp-skeleton-loader v-if="aprState.is('loading')" class="w-24 h-6"></pp-skeleton-loader>
        <pp-formatted-percent
          v-else-if="aprState.is('resolved')"
          :value="100 * aprState.response.totalApr"
        ></pp-formatted-percent>
        <pp-btn
          v-else-if="aprState.is('rejected')"
          color="p-red"
          variant="accent"
          @click="fetchApr"
        >
          Retry
        </pp-btn>
      </template>
    </pp-simple-data>

    <div v-if="aprState.is('resolved')" class="mt-2 space-y-2 text-sm text-water-600">
      <div v-for="apr in aprs" :key="apr.origin" class="flex flex-row items-center space-x-1">
        <div>{{ apr.origin }} APR</div>
        <pp-tooltip-trigger>
          <div v-for="comp in apr.composition" :key="comp.text">
            <div>
              <pp-formatted-percent
                class="font-semibold"
                :value="comp.percentage"
                :humanize="false"
              ></pp-formatted-percent>
              {{ comp.text }}
            </div>
          </div>
        </pp-tooltip-trigger>
        <pp-formatted-percent
          class="flex flex-grow justify-end"
          :value="apr.total"
        ></pp-formatted-percent>
      </div>
    </div>

    <div v-else-if="aprState.is('loading')" class="mt-1 space-y-2">
      <pp-simple-data>
        <template #left>
          <pp-skeleton-loader class="w-28 h-4"></pp-skeleton-loader>
        </template>
        <template #right>
          <pp-skeleton-loader class="w-24 h-4"></pp-skeleton-loader>
        </template>
      </pp-simple-data>
      <pp-simple-data>
        <template #left>
          <pp-skeleton-loader class="w-28 h-4"></pp-skeleton-loader>
        </template>
        <template #right>
          <pp-skeleton-loader class="w-24 h-4"></pp-skeleton-loader>
        </template>
      </pp-simple-data>
    </div>
  </div>
</template>

<script>
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'

  export default {
    props: {
      zap: { type: Object, required: true },
    },
    data() {
      return {
        aprState: createState({
          response: {
            composition: {
              otPoolApr: [],
              ytPoolApr: [],
            },
            totalApr: 0,
          },
        }),
      }
    },
    methods: {
      fetchApr() {
        new PromiseHandler(
          () => this.zap.contract(this.$store.getters['wallet/identity']).apr(),
          this.aprState
        ).execute()
      },
    },
    computed: {
      aprs() {
        const map = {}

        this.aprState.response.composition.otPoolApr.forEach((item) => {
          map[item.origin] = map[item.origin] || { ytPoolApr: 0, otPoolApr: 0 }
          map[item.origin].otPoolApr = 100 * item.apr
        })
        this.aprState.response.composition.ytPoolApr.forEach((item) => {
          map[item.origin] = map[item.origin] || { ytPoolApr: 0, otPoolApr: 0 }
          map[item.origin].ytPoolApr = 100 * item.apr
        })

        const result = Object.keys(map).map((origin) => {
          const { ytPoolApr, otPoolApr } = map[origin]

          return {
            origin,
            total: ytPoolApr + otPoolApr,
            composition: [
              { percentage: ytPoolApr, text: this.ytToken.symbol },
              { percentage: otPoolApr, text: this.otToken.symbol },
            ].filter((t) => t.percentage > 0),
          }
        })

        return result
      },
      ytToken() {
        return this.zap.receive.find((item) => item.tokenAmount.token.symbol.substr(0, 2) === 'YT')
          .tokenAmount.token
      },
      otToken() {
        return this.zap.receive.find((item) => item.tokenAmount.token.symbol.substr(0, 2) === 'OT')
          .tokenAmount.token
      },
    },
    watch: {
      zap: {
        immediate: true,
        handler() {
          this.fetchApr()
        },
      },
    },
  }
</script>
