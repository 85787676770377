<template>
  <div>
    <div>Output</div>

    <div class="grid grid-cols-2 gap-8 mt-2">
      <div
        v-for="{ source, tokenAmount, poolShare, allocation } in receivables"
        :key="tokenAmount.token.address"
      >
        <div class="font-semibold">
          <span v-if="source.className === 'StakingPool'" class="mr-1">Staked</span>
          <pp-token-symbol :token="tokenAmount.token"></pp-token-symbol>
        </div>

        <pp-simple-data v-if="source.className === 'StakingPool'">
          <template #left>
            <div class="flex flex-row items-center">
              <div>Pool share</div>

              <pp-tooltip-trigger class="ml-1" type="button">
                <div>
                  <pp-formatted-token-price
                    :token="tokenAmount.token"
                    :value="tokenAmount.formattedAmount()"
                  ></pp-formatted-token-price>
                  <span v-if="source.className === 'StakingPool'" class="ml-1">LP</span>
                </div>
                <pp-token-amount-valuation :tokenAmount="tokenAmount"></pp-token-amount-valuation>
              </pp-tooltip-trigger>
            </div>
          </template>
          <template #right>
            <pp-skeleton-loader v-if="loading" class="h-4 w-16"></pp-skeleton-loader>
            <pp-formatted-percent v-else :value="1 * poolShare"></pp-formatted-percent>
          </template>
        </pp-simple-data>

        <div v-if="source.className === 'StakingPool'" class="mt-3 text-sm text-water-600">
          Est. allocation
        </div>

        <pp-simple-data v-for="alloc in allocation" :key="alloc.token.address">
          <template #left>
            <div class="flex items-center text-sm">
              <pp-token-icon :token="alloc.token" class="h-6"></pp-token-icon>
              <pp-token-symbol :token="alloc.token" class="ml-1"></pp-token-symbol>
            </div>
          </template>
          <template #right>
            <pp-skeleton-loader v-if="loading" class="h-4 w-16"></pp-skeleton-loader>
            <pp-formatted-number v-else :value="alloc.formattedAmount()"></pp-formatted-number>
          </template>
        </pp-simple-data>
      </div>
    </div>
  </div>
</template>

<script>
  import TokenAmount from '@/domains/entities/TokenAmount'
  import Token from '@/domains/entities/Token'
  import Transaction from '@/domains/entities/Transaction'
  import { ACTIONS } from '@/app-config/zaps/actions'
  import { avalanche } from '@/app-config/constants'

  const PA_FORGE = '0x3acd2ff1c3450bc8a9765afd8d0dea8e40822c86-1703721600'
  const AVAX = avalanche.TOKENS.AVAX
  const WAVAX = avalanche.TOKENS.WAVAX

  export default {
    props: {
      zap: { type: Object, required: true },
      simulatedData: { type: Object, required: true },
      loading: { type: Boolean, default: false },
      inputType: { type: String, default: 'dual' },
      inputToken: { type: Object, required: false },
    },
    computed: {
      receivables() {
        return this.zap.receive.map((receivable) => ({
          ...receivable,
          poolShare: this.getPoolShare(receivable.tokenAmount.token),
          tokenAmount: this.getReceivedTokenBalance(receivable),
          allocation: this.getTokenAllocation(receivable),
        }))
      },
      transactions() {
        if (this.inputType === 'single') {
          let _transactions = [...this.zap.singleTransactions]
          let _baseTokenAddresses = [...this.zap.baseTokenAddresses]

          // *NOTE: special case for PENDLE/AVAX zap when input is AVAX
          // *All WAVAX tokens must be converted to AVAX (WAVAX -> AVAX)
          if (this.zap.yieldBearingAsset === PA_FORGE) {
            if (this.inputToken.address === AVAX) {
              _transactions = _transactions.map((transaction) => ({
                ...transaction,
                paid: transaction.paid.map((tokenAmount) =>
                  tokenAmount.token.address.toLowerCase() === WAVAX.toLowerCase()
                    ? new TokenAmount(Token.query().find(AVAX), 0)
                    : tokenAmount
                ),
              }))

              _baseTokenAddresses = _baseTokenAddresses.map((addr) =>
                addr.toLowerCase() === WAVAX.toLowerCase() ? AVAX : addr
              )
            }
          }

          let inputs = _baseTokenAddresses
            .filter((addr) => addr.toLowerCase() !== this.inputToken.address.toLowerCase())
            .map(
              (addr) =>
                new Transaction({
                  action: ACTIONS.SWAP,
                  paid: [new TokenAmount(this.inputToken, 0)],
                  received: [new TokenAmount(Token.query().find(addr), 0)],
                  protocol: 'TraderJoe',
                })
            )

          _transactions.unshift(...inputs)

          return _transactions
        } else {
          return [...this.zap.transactions]
        }
      },
    },
    methods: {
      getPoolShare(token) {
        if (!this.simulatedData) return '0.0'

        const tokenType = token.symbol.substr(0, 2)
        const { otPoolShare, ytPoolShare } = this.simulatedData.poolShares

        return { OT: 100 * otPoolShare, YT: 100 * ytPoolShare }[tokenType] || '0.0'
      },
      getReceivedTokenBalance(receivable) {
        if (!this.simulatedData) return receivable.tokenAmount

        const token = receivable.tokenAmount.token
        let balance = new TokenAmount(token, 0)

        this.simulatedData.transactions.forEach((transaction) => {
          transaction.received.forEach((receivedTokenAmount) => {
            if (token.isSameAs(receivedTokenAmount.token)) {
              balance = balance.plus(receivedTokenAmount)
            }
          })
        })

        return balance
      },
      getTokenAllocation(receivable) {
        const source = receivable.source.className
        const tokenType = receivable.tokenAmount.token.symbol.substr(0, 2)

        let tokenAmounts = []

        if (source === 'StakingPool') {
          const index = this.transactions.findIndex(
            (transaction) =>
              transaction.action === ACTIONS.ADD_LIQUIDITY &&
              transaction.paid.some(
                (tokenAmount) => tokenAmount.token.symbol.substr(0, 2) === tokenType
              )
          )
          tokenAmounts = this.getTxTokenAmounts(
            this.transactions[index].paid,
            this.simulatedData.transactions[index]?.paid || []
          )
        } else {
          const index = this.transactions.findIndex(
            (transaction) => transaction.action === ACTIONS.MINT
          )
          tokenAmounts = this.getTxTokenAmounts(
            this.transactions[index].received,
            this.simulatedData.transactions[index]?.received || []
          )
          const found = this.transactions[index].received.find(
            (tokenAmount) => tokenAmount.token.symbol.substr(0, 2) === tokenType
          )
          tokenAmounts = tokenAmounts.filter(
            (tokenAmount) =>
              tokenAmount.token.address.toLowerCase() === found.token.address.toLowerCase()
          )
        }

        return tokenAmounts
      },
      getTxTokenAmounts(tokenAmounts, simulatedTokenAmounts) {
        return tokenAmounts.map((tokenAmount) => {
          const found = simulatedTokenAmounts.find(
            (simulatedTokenAmount) =>
              tokenAmount.token.address.toLowerCase() ===
              simulatedTokenAmount.token.address.toLowerCase()
          )
          return found ? new TokenAmount(tokenAmount.token, found.rawAmount()) : tokenAmount
        })
      },
    },
  }
</script>
