<template>
  <div>
    <div class="text-xl font-medium">What is happening?</div>

    <ol class="mt-4 space-y-2 list-inside list-decimal">
      <li v-for="transaction in simulatedTransactions" :key="transaction.id" class="text-sm">
        <transaction-sentence :transaction="transaction" :loading="loading"></transaction-sentence>
      </li>
    </ol>
  </div>
</template>

<script>
  import TokenAmount from '@/domains/entities/TokenAmount'
  import Token from '@/domains/entities/Token'
  import Transaction from '@/domains/entities/Transaction'
  import TransactionSentence from './TransactionSentence.vue'
  import { ACTIONS } from '@/app-config/zaps/actions'
  import { avalanche } from '@/app-config/constants'

  const PA_FORGE = '0x3acd2ff1c3450bc8a9765afd8d0dea8e40822c86-1703721600'
  const AVAX = avalanche.TOKENS.AVAX
  const WAVAX = avalanche.TOKENS.WAVAX

  export default {
    components: {
      TransactionSentence,
    },
    props: {
      zap: { type: Object, required: true },
      simulatedData: { type: Object, required: true },
      loading: { type: Boolean, default: false },
      inputType: { type: String, default: 'dual' },
      inputToken: { type: Object, required: true },
    },
    computed: {
      transactions() {
        if (this.inputType === 'single') {
          let _transactions = [...this.zap.singleTransactions]
          let _baseTokenAddresses = [...this.zap.baseTokenAddresses]

          // *NOTE: special case for PENDLE/AVAX zap when input is AVAX
          // *All WAVAX tokens must be converted to AVAX (WAVAX -> AVAX)
          if (this.zap.yieldBearingAsset === PA_FORGE) {
            if (this.inputToken.address === AVAX) {
              _transactions = _transactions.map((transaction) => ({
                ...transaction,
                paid: transaction.paid.map((tokenAmount) =>
                  tokenAmount.token.address.toLowerCase() === WAVAX.toLowerCase()
                    ? new TokenAmount(Token.query().find(AVAX), 0)
                    : tokenAmount
                ),
              }))

              _baseTokenAddresses = _baseTokenAddresses.map((addr) =>
                addr.toLowerCase() === WAVAX.toLowerCase() ? AVAX : addr
              )
            }
          }

          let inputs = _baseTokenAddresses
            .filter((addr) => addr.toLowerCase() !== this.inputToken.address.toLowerCase())
            .map(
              (addr) =>
                new Transaction({
                  action: ACTIONS.SWAP,
                  paid: [new TokenAmount(this.inputToken, 0)],
                  received: [new TokenAmount(Token.query().find(addr), 0)],
                  protocol: 'TraderJoe',
                })
            )

          _transactions.unshift(...inputs)

          return _transactions
        } else {
          return [...this.zap.transactions]
        }
      },
      simulatedTransactions() {
        if (!this.simulatedData.transactions.length) return this.transactions

        return this.transactions.map((transaction, index) => {
          const simulatedTx = this.simulatedData.transactions[index]

          return {
            ...transaction,
            paid: this.getTxTokenAmounts(transaction.paid, simulatedTx.paid),
            received: this.getTxTokenAmounts(transaction.received, simulatedTx.received),
          }
        })
      },
    },
    methods: {
      getTxTokenAmounts(tokenAmounts, simulatedTokenAmounts) {
        return tokenAmounts.map((tokenAmount) => {
          const found = simulatedTokenAmounts.find(
            (simulatedTokenAmount) =>
              tokenAmount.token.address.toLowerCase() ===
              simulatedTokenAmount.token.address.toLowerCase()
          )
          return found ? new TokenAmount(tokenAmount.token, found.rawAmount()) : tokenAmount
        })
      },
    },
  }
</script>
