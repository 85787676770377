<template>
  <pp-form @submit.prevent="handleSubmit" class="flex flex-col flex-grow">
    <template v-slot:default="{ disabled: formDisabled }">
      <pp-card-body class="overflow-visible">
        <pp-token-amount-input-2
          label="Input"
          :value="inputAmount"
          @input="handleInput"
          :rules="[(val) => val > 0 || 'Amount must be larger than 0']"
          variant="picker"
          :token-options="tokenOptions"
          :common-options="commonOptions"
          :input-token="inputToken"
          @select="handleSelect"
        ></pp-token-amount-input-2>

        <div class="flex items-center mt-6">
          <div class="text-sm text-water-400 font-light">Slippage tolerance:</div>
          <pp-tooltip-trigger class="ml-1" type="button">
            The transaction will revert if there is a large, unfavourable price movement before it
            is confirmed.
          </pp-tooltip-trigger>
          <div class="ml-1 font-medium">{{ $app.state.slippage }}%</div>
        </div>
      </pp-card-body>

      <hr />

      <pp-card-body class="flex-grow overflow-visible">
        <output-section
          :zap="zap"
          :simulated-data="simulateState.response"
          :loading="simulateState.is('loading')"
          :input-type="inputType"
          :input-token="defaultInputToken"
        ></output-section>
      </pp-card-body>

      <pp-card-actions>
        <pp-tokens-approve-container
          :spending-amounts="spendingAmounts"
          :spender-address="zap.spenderAddress"
          v-slot:default="{ approved }"
        >
          <div v-if="approved">
            <pp-btn
              class="flex w-full"
              type="submit"
              size="xl"
              :loading="simulateState.is('loading') || submitState.is('loading') || isTxMining"
              :disabled="formDisabled"
            >
              Zap now
            </pp-btn>
          </div>
        </pp-tokens-approve-container>
      </pp-card-actions>
    </template>
  </pp-form>
</template>

<script>
  import OutputSection from './OutputSection.vue'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import Token from '@/domains/entities/Token'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import { debounce, generateEtherscanTxUrl } from '@/assets/helpers'
  import { avalanche } from '@/app-config/constants'

  const commonTokens = [
    avalanche.TOKENS.PENDLE,
    avalanche.TOKENS.AVAX,
    avalanche.TOKENS.USDC,
    avalanche.TOKENS.USDT,
    avalanche.TOKENS.WAVAX,
    avalanche.TOKENS.DAI,
    avalanche.TOKENS.MIM,
  ].map((addr) => addr.toLowerCase())

  export default {
    components: {
      OutputSection,
    },
    props: {
      zap: { type: Object, required: true },
      defaultInputToken: { type: Object, required: true },
      inputType: { type: String, default: 'dual' },
    },
    inject: ['traderJoeTokensState'],
    data() {
      return {
        inputToken: this.$props.defaultInputToken,
        inputAmount: '',
        simulateState: createState({
          response: {
            poolShares: {
              otPoolShare: '0',
              ytPoolShare: '0',
            },
            tokenAmounts: this.$props.zap.receive.map((receivable) => receivable.tokenAmount),
            transactions: [],
          },
        }),
        submitState: createState(),
        interactedInputIndex: -1,
        isTxMining: false,
      }
    },
    computed: {
      spendingAmounts() {
        return [new TokenAmount(this.inputToken, this.inputAmount || 0, false)]
      },
      simulationDetails() {
        return this.simulateState.response
      },
      zapContract() {
        return this.zap.contract(this.$store.getters['wallet/identity'])
      },
      slippage() {
        return 0.01 * this.$app.state.slippage
      },
      traderJoeTokens() {
        return this.traderJoeTokensState.response
      },
      tokenOptions() {
        const t1 = Token.query().where('tokenCategories', (val) => val.includes('traderJoeDefault'))
        const t2 = this.traderJoeTokens
          .filter((token) => token.chainId === 43114)
          .map(
            (token) =>
              new Token({
                ...token,
                address: token.address.toLowerCase(),
                img: token.logoURI,
                ignoreApproval: [], // !important
                network: 'avalanche', // !important
              })
          )
        const t3 = t1.concat(t2).reduce((a, b) => {
          if (!a.some((t) => b.address === t.address)) a.push(b)
          return a
        }, [])
        return t3
      },
      commonOptions() {
        return this.tokenOptions.filter((token) => commonTokens.includes(token.address))
      },
    },
    methods: {
      handleSelect(token) {
        this.inputToken = token
        this.$emit('select', token)
      },
      handleSubmit() {
        new PromiseHandler(
          () =>
            this.zapContract.send({
              simulationDetails: this.simulationDetails,
              slippage: this.slippage,
            }),
          this.submitState
        )
          .execute()
          .then((response) => {
            this.isTxMining = true
            response
              .wait(1)
              .then(() => {
                setTimeout(() => {
                  this.$notification.success({
                    title: 'Zap Transaction Success',
                    text: '',
                    action: {
                      url: generateEtherscanTxUrl(response.hash),
                      urlText: 'View on explorer',
                    },
                  })
                  this.$emit('success', response)
                  this.isTxMining = false
                }, 2000)
              })
              .catch(() => {
                this.isTxMining = false
              })
          })
          .catch((error) => {
            this.$notification.error({
              title: 'Zap Transaction Failed',
              text: 'Please try again',
            })
          })
      },
      handleInput(value) {
        this.inputAmount = value
        this.fetchSimulatedValuesDebounced()
      },
      fetchSimulatedValuesDebounced: debounce(function (...args) {
        this.fetchSimulatedValues(...args)
      }, 500),
      fetchSimulatedValues() {
        const tokenAmount = new TokenAmount(this.inputToken, this.inputAmount, false)

        this.$emit('loading', true)

        new PromiseHandler(
          () =>
            this.zapContract.simulateSingle({
              tokenAmount,
              slippage: this.slippage,
            }),
          this.simulateState
        )
          .execute({ force: true })
          .then((response) => {
            this.$emit('simulate', response)
            this.$emit('loading', false)
          })
          .catch((error) => {
            this.$emit('loading', false)
          })
      },
    },
    watch: {
      '$app.state.slippage': {
        immediate: false,
        handler() {
          this.fetchSimulatedValues()
        },
      },
    },
  }
</script>
