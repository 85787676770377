<template>
  <div class="flex flex-col flex-grow">
    <div class="flex space-x-1 p-1">
      <pp-tab
        v-for="tab in actions"
        :key="tab.action"
        size="lg"
        tag="router-link"
        variant="text"
        :to="{ name: 'ZapShow', params: { action: tab.action } }"
        border="bottom"
        :active="$route.params.action === tab.action"
      >
        {{ tab.title }}
      </pp-tab>
    </div>

    <hr class="-mt-1" />

    <pp-card-title>
      <pp-tab-container>
        <pp-tab
          v-for="tab in inputTypes"
          :key="tab.inputType"
          :tag="tab.disabled ? 'button' : 'router-link'"
          :to="{ name: 'ZapShow', params: { inputType: tab.inputType } }"
          :active="$route.params.inputType === tab.inputType"
          :disabled="tab.disabled"
        >
          {{ tab.title }}
        </pp-tab>
      </pp-tab-container>
    </pp-card-title>

    <single-token-form
      v-if="$route.params.inputType === 'single'"
      :zap="zap"
      :default-input-token="defaultInputToken"
      :input-type="inputType"
      @select="$emit('select', $event)"
      @simulate="$emit('simulate', $event)"
      @success="$emit('success', $event)"
      @loading="$emit('loading', $event)"
    ></single-token-form>

    <dual-tokens-form
      v-else
      :zap="zap"
      @simulate="$emit('simulate', $event)"
      @success="$emit('success', $event)"
      @loading="$emit('loading', $event)"
      :disabled="disableForm"
    ></dual-tokens-form>
  </div>
</template>

<script>
  import SingleTokenForm from './SingleTokenForm.vue'
  import DualTokensForm from './DualTokensForm.vue'

  export default {
    components: {
      SingleTokenForm,
      DualTokensForm,
    },
    props: {
      zap: { type: Object, required: true },
      defaultInputToken: { type: Object, required: true },
      inputType: { type: String, default: 'dual' },
    },
    data() {
      return {
        actions: [
          { title: 'YT & OT', action: 'OTYT' },
          { title: 'YT only', action: 'YT' },
          { title: 'OT Only', action: 'OT' },
        ],
      }
    },
    computed: {
      disableSingle() {
        return this.zap.denyInputTypes.includes('single')
      },
      disableDual() {
        return this.zap.denyInputTypes.includes('dual')
      },
      inputTypes() {
        return [
          {
            title: 'Single Token',
            inputType: 'single',
            disabled: this.disableSingle,
          },
          {
            title: 'Dual Tokens',
            inputType: 'dual',
            disabled: this.disableDual,
          },
        ]
      },
      disableForm() {
        const found = this.inputTypes.find(
          (inputType) => inputType.inputType === this.$route.params.inputType
        )
        return found.disabled
      },
    },
    created() {
      if (this.disableDual) {
        if (this.$route.name === 'ZapShow' && this.$route.params.inputType !== 'single') {
          this.$router.push({ name: 'ZapShow', params: { inputType: 'single' } })
        }
      }

      if (this.disableSingle) {
        if (this.$route.name === 'ZapShow' && this.$route.params.inputType !== 'dual') {
          this.$router.push({ name: 'ZapShow', params: { inputType: 'dual' } })
        }
      }
    },
  }
</script>
