<template>
  <pp-form @submit.prevent="handleSubmit" class="flex flex-col flex-grow">
    <template v-slot:default="{ disabled: formDisabled }">
      <pp-card-body class="overflow-visible">
        <div v-for="(token, index) in inputTokens" :key="token.address">
          <pp-token-amount-input-2
            :label="index === 0 ? 'Input' : ''"
            :value="inputAmounts[index]"
            @input="(value) => handleInput(value, index)"
            :input-token="token"
            :secondary-text="getValuation(index)"
            :secondary-text-loading="tokenValuations.is('loading')"
            :rules="[(val) => val > 0 || 'Amount must be larger than 0']"
            :disabled="disabled"
          ></pp-token-amount-input-2>

          <template v-if="index < inputTokens.length">
            <div class="my-5"></div>
          </template>
        </div>

        <div class="flex items-center justify-between mt-6">
          <div class="flex items-center">
            <div class="text-sm text-water-400 font-light">Slippage tolerance:</div>
            <pp-tooltip-trigger class="ml-1" type="button">
              The transaction will revert if there is a large, unfavourable price movement before it
              is confirmed.
            </pp-tooltip-trigger>
            <div class="ml-1 font-medium">{{ $app.state.slippage }}%</div>
          </div>

          <pp-obtain-token-link
            v-if="Boolean(zap.linkToObtain)"
            :address="zap.linkToObtain.tokenAddress"
          ></pp-obtain-token-link>
        </div>
      </pp-card-body>

      <hr />

      <pp-card-body class="flex-grow overflow-visible">
        <output-section
          :zap="zap"
          :simulated-data="simulateState.response"
          :loading="simulateState.is('loading')"
        ></output-section>
      </pp-card-body>

      <pp-card-actions>
        <pp-tokens-approve-container
          :spending-amounts="spendingAmounts"
          :spender-address="zap.spenderAddress"
          v-slot:default="{ approved }"
        >
          <div v-if="approved">
            <pp-btn
              class="flex w-full"
              type="submit"
              size="xl"
              :loading="simulateState.is('loading') || submitState.is('loading') || isTxMining"
              :disabled="formDisabled || disabled"
            >
              Zap now
            </pp-btn>
          </div>
        </pp-tokens-approve-container>
      </pp-card-actions>
    </template>
  </pp-form>
</template>

<script>
  import OutputSection from './OutputSection.vue'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import Valuation from '@/domains/Valuation'
  import TokenAmountCollection from '@/domains/collections/TokenAmountCollection'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import { debounce, generateEtherscanTxUrl } from '@/assets/helpers'

  const toPercent = (n, d) => (d != 0 ? (100 * n) / d : 0)

  export default {
    components: {
      OutputSection,
    },
    props: {
      zap: { type: Object, required: true },
      disabled: { type: Boolean, default: false },
    },
    data() {
      return {
        inputAmounts: this.$props.zap.inputTokens.map(() => ''),
        tokenValuations: createState({
          response: this.$props.zap.inputTokens.map(
            () => new Valuation({ currency: 'USD', amount: 0 })
          ),
        }),
        simulateState: createState({
          response: {
            poolShares: {
              otPoolShare: '0',
              ytPoolShare: '0',
            },
            tokenAmounts: this.$props.zap.receive.map((receivable) => receivable.tokenAmount),
            transactions: [],
          },
        }),
        submitState: createState(),
        interactedInputIndex: -1,
        isTxMining: false,
      }
    },
    computed: {
      inputTokens() {
        return this.zap.inputTokens
      },
      spendingAmounts() {
        return this.inputTokens.map(
          (token, index) => new TokenAmount(token, this.inputAmounts[index] || 0, false)
        )
      },
      simulationDetails() {
        return this.simulateState.response
      },
      zapContract() {
        return this.zap.contract(this.$store.getters['wallet/identity'])
      },
      slippage() {
        return 0.01 * this.$app.state.slippage
      },
    },
    methods: {
      handleSubmit() {
        new PromiseHandler(
          () =>
            this.zapContract.send({
              simulationDetails: this.simulationDetails,
              slippage: this.slippage,
            }),
          this.submitState
        )
          .execute()
          .then((response) => {
            this.isTxMining = true
            response
              .wait(1)
              .then(() => {
                setTimeout(() => {
                  this.$notification.success({
                    title: 'Zap Transaction Success',
                    text: '',
                    action: {
                      url: generateEtherscanTxUrl(response.hash),
                      urlText: 'View on explorer',
                    },
                  })
                  this.$emit('success', response)
                  this.isTxMining = false
                }, 2000)
              })
              .catch(() => {
                this.isTxMining = false
              })
          })
          .catch((error) => {
            this.$notification.error({
              title: 'Zap Transaction Failed',
              text: 'Please try again',
            })
          })
      },
      handleInput(value, index) {
        const array = this.inputAmounts.slice()
        array[index] = value
        array[(index + 1) % 2] = ''
        this.inputAmounts = array
        this.fetchSimulatedValuesDebounced(index)
        this.interactedInputIndex = value ? index : -1
      },
      fetchSimulatedValuesDebounced: debounce(function (...args) {
        this.fetchSimulatedValues(...args)
      }, 500),
      fetchSimulatedValues(index) {
        if (index === -1) return

        const inputToken = this.inputTokens[index]
        const inputAmount = this.inputAmounts[index]
        const tokenAmount = new TokenAmount(inputToken, inputAmount, false)

        this.$emit('loading', true)

        new PromiseHandler(
          () =>
            this.zapContract.simulateDual({
              tokenAmount,
              slippage: this.slippage,
            }),
          this.simulateState
        )
          .execute({ force: true })
          .then((response) => {
            this.$emit('simulate', response)
            this.$emit('loading', false)
            this.setTokenValue(index, response)
          })
          .catch(() => {
            this.$emit('loading', false)
          })
      },
      setTokenValue(index, response) {
        const inputTokens = this.inputTokens.slice().reverse()
        const inputToken = inputTokens[index]
        const tokenAmount = response.tokenAmounts.find(
          (tokenAmount) => tokenAmount.token.address === inputToken.address
        )
        inputTokens[index] = tokenAmount.formattedAmount()

        const array = this.inputAmounts.slice().reverse()
        array[index] = tokenAmount.formattedAmount()
        this.inputAmounts = array.reverse()
      },
      updateValuations() {
        const tokenAmounts = this.inputTokens.map(
          (inputToken, index) => new TokenAmount(inputToken, this.inputAmounts[index] || '0', false)
        )

        new PromiseHandler(
          () =>
            TokenAmountCollection.fromArray(tokenAmounts)
              .contract(this.$store.getters['wallet/identity'])
              .fetchValuations(),
          this.tokenValuations
        ).execute()
      },
      getValuation(index) {
        const tokenValuations = this.tokenValuations.response
        const total = tokenValuations.reduce(
          (a, b) => a.plus(b),
          new Valuation({ currency: 'USD', amount: 0 })
        ).amount
        const amount = toPercent(tokenValuations[index].amount, total)
        return `${amount.toFixed(0)}%`
      },
    },
    watch: {
      'simulateState.response': {
        immediate: true,
        handler() {
          this.$nextTick(() => {
            this.updateValuations()
          })
        },
      },
      '$app.state.slippage': {
        immediate: false,
        handler() {
          this.fetchSimulatedValues(this.interactedInputIndex)
        },
      },
    },
  }
</script>
