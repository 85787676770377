<template>
  <div class="container mx-auto">
    <div class="py-12 flex justify-center">
      <div class="max-w-[900px] w-full">
        <pp-back-button :to="{ name: 'Zap' }"></pp-back-button>

        <div class="mt-5">
          <template v-if="zap">
            <zap-container :zap="zap" :input-type="inputType" :key="key"></zap-container>
          </template>

          <template v-else>
            <pp-not-found></pp-not-found>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ZapContainer from '@/components/zaps/ZapContainer.vue'
  import Zap from '@/domains/entities/Zap'

  export default {
    components: {
      ZapContainer,
    },
    data() {
      return {
        key: 0,
      }
    },
    computed: {
      zap() {
        return Zap.query()
          .where('yieldBearingAsset', this.$route.params.yieldBearingAsset)
          .where('action', this.$route.params.action)
          .first()
      },
      inputType() {
        return this.$route.params.inputType
      },
    },
    watch: {
      zap: {
        immediate: true,
        handler() {
          this.key++
        },
      },
    },
  }
</script>
