<template>
  <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-10">
    <zap-details
      :zap="zap"
      :simulated-data="simulatedData"
      :loading="loading"
      :input-type="inputType"
      :input-token="inputToken"
      :key="resetCount"
    ></zap-details>

    <pp-card>
      <zap-form-container
        :zap="zap"
        :default-input-token="inputToken"
        :simulated-data="simulatedData"
        :input-type="inputType"
        @simulate="handleSimulate"
        @select="handleSelect"
        @success="handleSuccess"
        @loading="handleLoading"
        :key="resetCount"
      ></zap-form-container>
    </pp-card>
  </div>
</template>

<script>
  import ZapDetails from './ZapDetails.vue'
  import ZapFormContainer from './ZapFormContainer.vue'
  import Token from '@/domains/entities/Token'
  import { avalanche } from '@/app-config/constants'

  export default {
    components: {
      ZapDetails,
      ZapFormContainer,
    },
    props: {
      zap: { type: Object, required: true },
      inputType: { type: String, default: 'dual' },
    },
    data() {
      return {
        simulatedData: {
          tokenAmounts: [],
          poolShares: { otPoolShare: '0', ytPoolShare: '0' },
          transactions: [],
        },
        inputToken: Token.query().find(avalanche.TOKENS.USDC), // USDC: initial default value
        resetCount: 0,
        loading: false,
      }
    },
    methods: {
      handleSimulate(data) {
        this.simulatedData = data
      },
      handleSelect(token) {
        this.resetSimulatedData()
        this.inputToken = token
        this.resetCount++
      },
      handleSuccess() {
        this.resetSimulatedData()
        this.resetCount++
      },
      handleLoading(value) {
        this.loading = value
      },
      resetSimulatedData() {
        this.simulatedData = {
          tokenAmounts: [],
          poolShares: { otPoolShare: '0', ytPoolShare: '0' },
          transactions: [],
        }
      },
    },
    watch: {
      zap: {
        immediate: true,
        handler() {
          this.resetSimulatedData()
        },
      },
    },
  }
</script>
