<template>
  <div class="inline-flex items-center space-x-1 text-sm text-water-600">
    <template v-if="transaction.action === actions.ADD_LIQUIDITY">
      <div>
        <div class="inline-flex items-center space-x-1">
          <div>Provide</div>
          <div class="font-medium text-p-blue-800">
            <pp-token-symbol :token="transaction.received[0].token"></pp-token-symbol>
          </div>
          <div>liquidity on {{ transaction.protocol }}</div>
        </div>
        <div class="mt-2 flex space-x-1">
          <template v-for="(tokenAmount, index) in transaction.paid">
            <pp-token-amount-2
              :token-amount="tokenAmount"
              :key="tokenAmount.token.address"
              :loading="loading"
            ></pp-token-amount-2>

            <template v-if="index < transaction.paid.length - 1">
              <div :key="index">+</div>
            </template>
          </template>
        </div>
      </div>
    </template>

    <template v-if="transaction.action === actions.DEPOSIT">
      <div>
        <div class="inline-flex items-center space-x-1">
          <div>Deposit</div>
          <div class="font-medium text-p-blue-800">
            <pp-token-symbol :token="transaction.paid[0].token"></pp-token-symbol>
          </div>
          <div>on {{ transaction.protocol }}</div>
        </div>
        <div class="mt-2 flex space-x-1">
          <template v-for="(tokenAmount, index) in transaction.paid">
            <pp-token-amount-2
              :token-amount="tokenAmount"
              :key="tokenAmount.token.address"
              :loading="loading"
            ></pp-token-amount-2>

            <template v-if="index < transaction.paid.length - 1">
              <div :key="index">+</div>
            </template>
          </template>
        </div>
      </div>
    </template>

    <template v-if="transaction.action === actions.WRAP">
      <div>
        <div class="inline-flex items-center space-x-1">
          <div>Wrap</div>
          <div class="font-medium text-p-blue-800">
            <pp-token-symbol :token="transaction.paid[0].token"></pp-token-symbol>
          </div>
          <div>into</div>
          <div class="font-medium text-p-blue-800">
            <pp-token-symbol :token="transaction.received[0].token"></pp-token-symbol>
          </div>
          <div>on {{ transaction.protocol }}</div>
        </div>
        <div class="mt-2 flex space-x-1">
          <template v-for="(tokenAmount, index) in transaction.paid">
            <pp-token-amount-2
              :token-amount="tokenAmount"
              :key="tokenAmount.token.address"
              :loading="loading"
            ></pp-token-amount-2>

            <template v-if="index < transaction.paid.length - 1">
              <div :key="index">+</div>
            </template>
          </template>
        </div>
      </div>
    </template>

    <template v-else-if="transaction.action === actions.MINT">
      <div>Mint</div>
      <div class="font-medium text-p-blue-800">
        {{ mintedTokens }}
      </div>
    </template>

    <template v-else-if="transaction.action === actions.STAKE">
      <div>Stake</div>
      <div class="font-medium text-p-blue-800">
        <pp-token-symbol :token="transaction.paid[0].token"></pp-token-symbol>
      </div>
    </template>

    <template v-if="transaction.action === actions.SWAP">
      <div>
        <div class="inline-flex items-center space-x-1">
          <div>Swap</div>
          <div class="font-medium text-p-blue-800">
            <pp-token-symbol :token="transaction.paid[0].token"></pp-token-symbol>
          </div>
          <div>to</div>
          <div class="font-medium text-p-blue-800">
            <pp-token-symbol :token="transaction.received[0].token"></pp-token-symbol>
          </div>
          <div>on {{ transaction.protocol }}</div>
        </div>
        <div class="mt-2 flex items-center space-x-1">
          <pp-token-amount-2
            :token-amount="transaction.paid[0]"
            :loading="loading"
          ></pp-token-amount-2>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 7l5 5m0 0l-5 5m5-5H6"
              />
            </svg>
          </div>
          <pp-token-amount-2
            :token-amount="transaction.received[0]"
            :loading="loading"
          ></pp-token-amount-2>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { ACTIONS } from '@/app-config/zaps/actions'

  export default {
    props: {
      transaction: { type: Object, default: () => ({ paid: [], received: [] }) },
      loading: { type: Boolean, default: false },
    },
    computed: {
      actions() {
        return ACTIONS
      },
      mintedTokens() {
        return this.transaction.received.map((tokenAmount) => tokenAmount.token.symbol).join(' + ')
      },
    },
  }
</script>
