<template>
  <div>
    <div class="text-2xl font-semibold">
      Zap for {{ zap.transactions[0].received[0].token.symbol }}
    </div>
    <div class="mt-1 font-light">Multi step transaction made easy.</div>

    <hr class="my-4" />

    <apr-section :zap="zap"></apr-section>

    <hr class="my-4" />

    <transactions-section
      :zap="zap"
      :simulated-data="simulatedData"
      :loading="loading"
      :input-type="inputType"
      :input-token="inputToken"
    ></transactions-section>
  </div>
</template>

<script>
  import AprSection from './AprSection.vue'
  import TransactionsSection from './TransactionsSection.vue'

  export default {
    components: {
      AprSection,
      TransactionsSection,
    },
    props: {
      zap: { type: Object, required: true },
      simulatedData: { type: Object, required: true },
      loading: { type: Boolean, default: false },
      inputType: { type: String, default: 'dual' },
      inputToken: { type: Object, required: true },
    },
  }
</script>
